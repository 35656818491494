import React from 'react'

const PreviewCompatibleHTML = ({ content }) => {
  if (typeof content === 'object') {
    return <div>{content}</div>
  } else {
    return <div dangerouslySetInnerHTML={{ __html: content }} />
  }
}

export default PreviewCompatibleHTML

import PropTypes from 'prop-types'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Accordion from 'react-bootstrap/Accordion'
import Section from '../components/Section'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import PreviewCompatibleHTML from '../components/PreviewCompatibleHTML'
import remark from 'remark'
import remarkHtml from 'remark-html'

const getHtml = content => {
  return remark().use(remarkHtml).processSync(content).toString()
}

const HelpPageTemplate = ({ faq, gettingStarted }) => {
  return (
    <>
      <Section
        style={{
          width: '100vw',
          background: 'var(--gradient-green)',
          color: 'white',
        }}>
        <Container>
          <h1>Help</h1>
        </Container>
      </Section>

      <Container>
        <Row>
          <Col xl={6} className='py-5'>
            <h2>FAQ</h2>
            <Accordion defaultActiveKey='0'>
              {faq.map((x, index) => (
                <Accordion.Item key={`faq-${index}`} eventKey={index}>
                  <Accordion.Header>{x.question}</Accordion.Header>
                  <Accordion.Body>{x.answer}</Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </Col>

          <Col xl={6} className='py-5'>
            <h2>Quick Start</h2>
            <Accordion defaultActiveKey='0'>
              {gettingStarted.map((x, index) => (
                <Accordion.Item key={`qs-${index}`} eventKey={index}>
                  <Accordion.Header>{x.title}</Accordion.Header>
                  <Accordion.Body>
                    <PreviewCompatibleHTML content={getHtml(x.description)} />

                    {x.image && (
                      <div className='mt-2 mb-4 text-center'>
                        <PreviewCompatibleImage
                          imageInfo={{
                            image: x.image,
                            alt: `Moolah`,
                            style: { maxWidth: 1200 },
                          }}
                        />
                      </div>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </Col>
        </Row>
      </Container>
    </>
  )
}

HelpPageTemplate.propTypes = {
  faq: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string,
      answer: PropTypes.string,
    })
  ),

  gettingStarted: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
    })
  ),
}

export default HelpPageTemplate

import { graphql } from 'gatsby'
import React from 'react'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import HelpPageTemplate from './help-page-template'

const HelpPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <Seo title='Moolah - Help' />
      <HelpPageTemplate
        faq={frontmatter.faq}
        gettingStarted={frontmatter.gettingStarted}
      />
    </Layout>
  )
}

export default HelpPage

export const pageQuery = graphql`
  query HelpPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "help-page" } }) {
      frontmatter {
        faq {
          question
          answer
        }
        gettingStarted {
          title
          description
          image {
            childImageSharp {
              gatsbyImageData(
                width: 1200
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  }
`
